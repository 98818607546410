import Globals from "../shared/Globals";
import { qreq } from "../shared/qrequest";
import AdminLayout from "./AdminLayout";
import BaseCom from "./BaseCom";
import Card from "./Card";
import FormInput from "./FormInput";
import FormLabel from "./FormLabel";
import Icon from "./Icon";
import { withParamsAndNavigate } from "./WithParamsAndNavigate";

class AdminOrgnaizationsEdit extends BaseCom {
    constructor(props) {
        super(props);
        this.state = {
            info: {}
        };
        this.submit = this.submit.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    componentDidMount() {
        if (this.props.params.id) {
            qreq.get('/api/admin/merchant/get?id=' + this.props.params.id, j => {
                if (j.item)
                    this.setState({ info: j.item });
                else
                    this.alert(j.errorMessage);
            }, this.unkownErrorCallback);
        }
    }

    onChange(v) {
        this.setState({ info: { ...this.state.info } });
    }

    submit(e) {
        if (e) e.preventDefault();
        qreq.post('/api/admin/merchant/save', { ...this.state.info }, j => {
            if (j.errorCode)
                this.alert(j.errorMessage);
            else {
                this.success('Merchant saved!');
                this.props.navigate('/admin/merchants');
            }
        }, this.unkownErrorCallback);
    }

    render() {
        return <AdminLayout>
            <div className="container-fluid">
                <form onSubmit={this.submit}>
                    <Card title="Merchant Info">
                        <div className="row">
                            <div className="col-md-10">
                                <div className="row">
                                    <div className="col-md-6">
                                        <FormLabel model={this.state.info.name} label="Merchant Name" type="text" />
                                    </div>
                                    <div className="col-md-6">
                                        <FormLabel model={this.state.info.merchantID} label="Merchant ID" type="text" />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <FormLabel model={this.state.info.email} label="Email" type="text" />
                                    </div>
                                    <div className="col-md-6">
                                        <FormLabel model={this.state.info.phone} label="Phone" type="text" />
                                    </div>
                                </div>
                                <FormLabel model={this.state.info.category} label="Category" type="text" />
                            </div>
                            <div className="col-md-2 text-center">
                                {this.state.info.logoIdent ? <img src={Globals.imageUrl(this.state.info.publicLogoURL)} alt="logo" className="img-fluid" /> : <span className="text-muted">No Logo</span>}
                            </div>
                        </div>
                    </Card>
                    <div className="app-form-buttons">
                        <button onClick={this.goBack} type="button" className="btn btn-secondary"><Icon icon="faCancel" /> Cancel</button>
                    </div>
                </form>
            </div>
        </AdminLayout>
    }
}

export default withParamsAndNavigate(AdminOrgnaizationsEdit);