import "@fontsource/roboto";
import './App.css';
import * as React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Portal from "./layouts/Portal";
import ConnError from "./components/errors/ConnError";
import NotFound from "./components/errors/NotFound";
import { qreq } from "./shared/qrequest";
import Globals from "./shared/Globals";
import Editor from "./components/Editor";
import SelectTemplate from "./components/SelectTemplate";
import StartPage from "./components/StartPage";
import SelectPlan from "./components/SelectPlan";
import SitePages from "./components/SitePages";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './css/project.css';
import Login from "./components/Login";
import ForgotPassword from "./components/ForgotPassword";
import SetPassword from "./components/SetPassword";
import BaseCom from "./components/BaseCom";
import Index from "./components/Index";
import Logout from "./components/Logout";
import AdminTemplates from "./components/AdminTemplates";
import AdminSettings from "./components/AdminSettings";
import AdminUsers from "./components/AdminUsers";
import AdminUsersEdit from "./components/AdminUsersEdit";
import AdminSites from "./components/AdminSites";
import { PopModal } from "./components/PopModal";
import AdminHome from "./components/AdminHome";
import AdminSitesEdit from "./components/AdminSitesEdit";
import Session from "./components/Session";
import AdminLayoutWithOutlet from "./components/AdminLayoutWithOutlet";
import AdminOrganizations from "./components/AdminOrganizations";
import AdminOrganizationsEdit from "./components/AdminOrganizationsEdit";
import AdminTemplatesEdit from "./components/AdminTemplatesEdit";
import PurchaseComplete from "./components/PurchaseComplete";
import { loadLang, pullCurrentLang, setCurrentLang } from './shared/GLang';
import AdminMerchants from "./components/AdminMerchants";
import AdminMerchantsEdit from "./components/AdminMerchantsEdit";

export const AppGlobal = {
    setLang() {}
};

export default class App extends BaseCom {

    constructor(props) {
        super(props);
        this.state = {
            user: null,
            loaded: false,
            connectionError: false
        };
        this.setUser = this.setUser.bind(this);
        this.tick = this.tick.bind(this);
        this.setLang = this.setLang.bind(this);
        AppGlobal.setLang = this.setLang;
    }

    componentDidMount() {
        qreq.get('/api/status/check', (j) => {
            this.setState({ connectionError: false });
            this.interval = setInterval(this.tick, 10000);
            this.tick();
        }, (e) => {
            this.setState({ connectionError: true });
        });       
        loadLang(() => {
            pullCurrentLang(lang => {
                this.setState({ lang: lang });
            });            
        });
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    setLang (lang) {
        Globals.setLang(lang);
        qreq.post('/api/content/lang/set/' + lang);
        this.setState({ lang: lang });
    }

    tick() {
        if (this.tickInProcess)
            return;
        this.tickInProcess = true;
        /*
        Auth.getLogin(user => {
            var pn = Globals.getOutsidePath(document.location.pathname);
            if (user)
                this.setState({ user: user });
            else {
                this.setState({ user: null });
                if (Globals.outsidePaths.indexOf(pn) === -1)
                    document.location.replace('/login');
            }
        });
        */
        this.tickInProcess = false;
    }

    setUser(user) {
        Globals.set('user', user);
        this.setState({ user: user, loaded: true });
    }

    render() {
        if (this.state.connectionError)
            return (<ConnError />);
        return (<>
            <ToastContainer position="top-center"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light" />
            <PopModal />
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Portal />}>
                        <Route index path="/" element={<Index />} />
                        <Route path="/index" element={<Index />} />
                        <Route path="/start" element={<StartPage />} />
                        <Route path="/plan" element={<SelectPlan />} />
                        <Route path="/template" element={<SelectTemplate />} />
                        <Route path="/page/:tab" element={<SitePages />} />
                        <Route path="/editor" element={<Editor index />} />
                        <Route path="/editor/new" element={<Editor new />} />
                        <Route path="/editor/page/:id" element={<Editor />} />
                        <Route path="/editor/site/:templateID" element={<Editor index />} />
                        <Route path="/editor/site/:templateID/new" element={<Editor new />} />
                        <Route path="/editor/site/:templateID/page/:id" element={<Editor />} />
                        <Route path="/editor/template/new" element={<Editor template new />} />
                        <Route path="/editor/template/:templateID" element={<Editor template index />} />
                        <Route path="/editor/template/:templateID/new" element={<Editor template />} />
                        <Route path="/editor/template/:templateID/page/:id" element={<Editor template />} />
                        <Route path="/login" element={<Login />} />
                        <Route path="/logout" element={<Logout />} />
                        <Route path="/forgot-password" element={<ForgotPassword />} />
                        <Route path="/reset-password/:code" element={<SetPassword />} />
                        <Route path="/session/:sid" element={<Session />} />
                        <Route path="/payment/complete" element={<PurchaseComplete />} />

                        <Route path="/admin" element={<AdminLayoutWithOutlet />}>
                            <Route index element={<AdminHome />} />
                            <Route path="/admin/sites" element={<AdminSites />} />
                            <Route path="/admin/sites/new" element={<AdminSitesEdit />} />
                            <Route path="/admin/sites/edit/:id" element={<AdminSitesEdit />} />
                            <Route path="/admin/organizations" element={<AdminOrganizations />} />
                            <Route path="/admin/organizations/new" element={<AdminOrganizationsEdit />} />
                            <Route path="/admin/organizations/edit/:id" element={<AdminOrganizationsEdit />} />
                            <Route path="/admin/merchants" element={<AdminMerchants />} />
                            <Route path="/admin/merchants/edit/:id" element={<AdminMerchantsEdit />} />
                            <Route path="/admin/templates" element={<AdminTemplates />} />
                            <Route path="/admin/templates/edit/:id" element={<AdminTemplatesEdit />} />
                            <Route path="/admin/settings" element={<AdminSettings />} />
                            <Route path="/admin/users" element={<AdminUsers />} />
                            <Route path="/admin/users/edit/:id" element={<AdminUsersEdit />} />
                        </Route>

                        <Route path="*" element={<NotFound />} />
                    </Route>
                </Routes>
            </BrowserRouter>
        </>);
    }
}
