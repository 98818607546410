import { qreq } from "../shared/qrequest";
import BaseCom from "./BaseCom";
import FormInput from "./FormInput";
import L from "./Lang";
import { withParamsAndNavigate } from "./WithParamsAndNavigate";

class DomainSearch extends BaseCom {
    constructor(props) {
        super(props);
        this.state = {
            query: {},
            customInfo: {}
        };
        this.search = this.search.bind(this);
        this.select = this.select.bind(this);
    }

    search(e) {
        if (e) e.preventDefault();
        if (!this.state.query.search)
            this.setState({ list: [] });
        qreq.get('/api/domain/search?query=' + encodeURIComponent(this.state.query.search), j => {
            if (j.list)
                this.setState({ list: j.list });
            else
                this.alert(j.errorMessage);
        }, this.unkownErrorCallback);
    }

    select(item) {
        this.setState({ submitting: true });
        qreq.post('/api/domain/select?siteDomainID=' + (this.props.params.templateID ?? '0') + (this.props.reserveOnly ? '&reserveOnly=true' : ''), item, j => {
            if (j.redirectUrl)
                window.location.assign(j.redirectUrl);
            else if (this.props.reserveOnly && j.errorCode === 0)
                this.props.onSuccess();
            else
                this.alert(j.errorMessage);
            this.setState({ submitting: false });
        }, () => {
            this.setState({ submitting: false });
        });
    }

    submitCustom = async (e) => {
        e?.preventDefault();
        this.setState({ submitting: true });
        try
        {
            let j = await qreq.postAsync('/api/domain/select/custom?siteDomainID=' + (this.props.params.templateID ?? '0') + (this.props.reserveOnly ? '&reserveOnly=true' : ''), this.state.customInfo);
            if (j.redirectUrl)
                window.location.assign(j.redirectUrl);
            else if (this.props.reserveOnly && j.errorCode === 0)
                this.props.onSuccess();
            else
                this.alert(j.errorMessage);
        }
        catch(e) {
            this.unkownErrorCallback();
        }
        this.setState({ submitting: false });
    }

    render() {
        const content = <div className="container-fluid">
            {!this.state.bringMyDomain && <form onSubmit={this.search}>
                <div className="d-flex align-items-center gap-1">
                    <FormInput model={this.state.query} name="search" type="text" label="mydomainname.com" labelAsPlaceholder />
                    <button type="submit" className="btn btn-primary mb-3 rounded-0"><L>Check</L></button>
                </div>
                {this.state.list && <div className="list-group rounded-0">
                    {this.state.list.map(i => <div key={i.key} className="list-group-item d-flex align-items-center" style={{ height: '50px' }}>
                        <div className="flex-fill">
                            <span className={i.isAvailable ? 'text-success' : 'text-danger'}>
                                {i.domainName}
                            </span>
                            <span className="d-inline-block ms-1">
                                {i.isAvailable ? <div className="badge bg-success"><L>Available</L></div> : <div className="badge bg-danger"><L>Taken</L></div>}
                            </span>
                        </div>
                        {i.isAvailable ? <div>
                            <button type="button" className="btn btn-success" onClick={() => this.select(i)} disabled={this.state.submitting}>{this.props.reserveOnly ?<L>Select This Domain!</L> : <L>Buy Now!</L>}</button>
                        </div> : ''}
                    </div>)}
                </div>}
                <div className="text-center">
                    <span role="button" className="text-primary" onClick={() => this.setState({ bringMyDomain: true })} disabled={this.state.submitting}><L>I want to bring my own domain.</L></span>
                </div>
            </form>}
            {this.state.bringMyDomain && <form onSubmit={this.submitCustom}>
                <FormInput model={this.state.customInfo} name="domainName" type="text" label="Enter the domain name you already own" placeholder="example.com" onChange={() => this.setState({ customInfo: {...this.state.customInfo} })} />
                <div className="d-grid mb-3">
                    <button type="submit" className="btn btn-primary" disabled={this.state.submitting}><L>Continue</L></button>
                </div>
                <div className="text-center">
                    <span role="button" className="text-primary" onClick={() => this.setState({ bringMyDomain: false })}><L>I want to purchase a new domain name.</L></span>
                </div>
            </form>}
        </div>;
        if (this.props.noModal)
            return content;
        else
            return this.wrapModal(content, 'Domain Search', 'xl');
    }
}

export default withParamsAndNavigate(DomainSearch);