import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as fa from '@fortawesome/free-solid-svg-icons'
import FormInput from "./FormInput";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { qreq } from '../shared/qrequest';
import PropertiesEditor from './PropertiesEditor';
import { withParamsAndNavigate } from './WithParamsAndNavigate';
import { HTMLEditorGlobal } from './HTMLEditorModal';
import Globals from '../shared/Globals';
import { AIModalGlobal } from './AIModal';
import { EditorGlobal } from './Editor';
import BaseCom from './BaseCom';
import L from './Lang';

export class Toolbar extends BaseCom {

    constructor(props) {
        super(props);
        this.state = {
            col: props.col,
            showPropsModal: false,
            height: 68,
            textProps: {},
            fontSizes: []
        };

        for (var n = 10; n < 32; n++) {
            this.state.fontSizes.push({ id: n, name: String(n) });
        }
       
        this.idDiv = props.idDiv;
        this.focusMove = props.focusMove;
        this.hideToolbar = props.hideToolbar;
        this.getSelectionHTML = this.getSelectionHTML.bind(this);
        this.insertHTML = this.insertHTML.bind(this);
        this.insertImage = this.insertImage.bind(this);
        this.removeFormat = this.removeFormat.bind(this);
        this.insertMenu = this.insertMenu.bind(this);
        this.setFontSize = this.setFontSize.bind(this);
        this.createCol = props.createCol;
        this.removeCol = props.removeCol;
        this.showComProps = props.showComProps;
        this.onImageUpload = this.onImageUpload.bind(this);
        this.ai = this.ai.bind(this);
        this.toggleImgAI = this.toggleImgAI.bind(this);
    }

    getSelectionHTML() {
        var html = '';
        if (typeof window.getSelection != 'undefined') {
            var sel = window.getSelection();
            if (sel.rangeCount) {
                var container = document.createElement('div');
                for (var i = 0, len = sel.rangeCount; i < len; ++i) {
                    container.appendChild(sel.getRangeAt(i).cloneContents());
                }
                html = container.innerHTML;
            }
        } else if (typeof document.selection != 'undefined') {
            if (document.selection.type == 'Text') {
                html = document.selection.createRange().htmlText;
            }
        }
        return html;
    }

    getSelectionText() {
        var text = '';
        if (typeof window.getSelection != 'undefined') {
            var sel = window.getSelection();
            if (sel.rangeCount) {
                var container = document.createElement('div');
                for (var i = 0, len = sel.rangeCount; i < len; ++i) {
                    container.appendChild(sel.getRangeAt(i).cloneContents());
                }
                text = container.innerText;
            }
        }
        return text;
    }

    insertMenu() {
        var col = this.createCol('navbar-item', 'Menu Item');
        this.state.col.containers[0].cols.push(col);
        this.state.col.containers[0].subContainer = true;
        this.setState({ col: this.state.col });
    }

    setFontSize(obj) {
        this.insertHTML('<span style="font-size: ' + obj + 'px;" class="wbp-f">Write text...</span>');
    }

    insertHTML(html, rem) {
        var curHTML = this.getSelectionHTML();

        var sel, range;
        if (window.getSelection) {
            // IE9 and non-IE
            sel = window.getSelection();
            if (sel.getRangeAt && sel.rangeCount) {
                var el = document.createElement('div');
                el.innerHTML = html;

                if(sel.focusNode && sel.focusNode.className && sel.focusNode.className.indexOf(rem) != -1) {
                    if(sel.focusNode.className !== 'wbp-editor-content-data')
                    {
                        sel.focusNode.className = rem + ' ' + el.firstChild.className.replace(rem, '');
                        return;
                    }
                }

                range = sel.getRangeAt(0);
                if(range.collapsed) {
                    range.setStart(sel.focusNode, 0);
                    range.setEnd(sel.focusNode, sel.focusNode.length);
                    sel.addRange(range);
                    curHTML = this.getSelectionHTML();
                }
                range.deleteContents();

                var p = sel.focusNode.parentNode;
                var prev = p;
                if(!p || !p.classList || !p.classList.contains) return;
                while(!p.classList.contains('wbp-editor-content-data') && p.classList.contains(rem)) {
                    prev = p;
                    p = p.parentNode;
                    if(!p || !p.classList || !p.classList.contains) return;
                }

                if(p !== prev)
                    p.removeChild(prev);

                var frag = document.createDocumentFragment(), node, lastNode;
                while ((node = el.firstChild)) {
                    lastNode = frag.appendChild(node);
                }
                range.insertNode(frag);
                if (curHTML) {
                    console.log('curHTML', curHTML);
                    lastNode.innerHTML = curHTML;
                }
                if (lastNode) {
                    range = range.cloneRange();
                    range.selectNodeContents(lastNode);
                    console.log('lastNode', lastNode);
                    sel.removeAllRanges();
                    sel.addRange(range);
                }
            }
        } else if (document.selection && document.selection.type != 'Control') {
            // IE < 9
            document.selection.createRange().pasteHTML(html);
        }
    }

    removeFormat() {
        var curText = this.getSelectionText();

        var sel, range;
        if (window.getSelection) {
            // IE9 and non-IE
            sel = window.getSelection();
            if (sel.getRangeAt && sel.rangeCount) {
                range = sel.getRangeAt(0);
                if(range.startOffset === range.endOffset) {
                    range.setStart(sel.focusNode, 0);
                    range.setEnd(sel.focusNode, sel.focusNode.length);
                    sel.addRange(range);
                }
                range.deleteContents();


                var el = document.createElement('div');
                el.innerText = curText;
                var frag = document.createDocumentFragment(), node, lastNode;
                while ((node = el.firstChild)) {
                    lastNode = frag.appendChild(node);
                }
                range.insertNode(frag);
                if (curText)
                    lastNode.innerText = curText;
                if (lastNode) {
                    range = range.cloneRange();
                    range.selectNodeContents(lastNode);
                    sel.removeAllRanges();
                    sel.addRange(range);
                }

                /*
                if(sel.focusNode && sel.focusNode.parentNode)
                {
                    var curNode = sel.focusNode.parentNode;
                    if(curNode.className != 'wbp-editor-content-data')
                    {
                        var curParnetNode = curNode.parentNode;
                        var newNode = document.createElement('div');
                        newNode.innerHTML = curNode.innerHTML;
                        curParnetNode.insertBefore(newNode, curNode);
                        curParnetNode.removeChild(curNode);
                    }
                }
                */
                
            }
        } else if (document.selection && document.selection.type !== 'Control') {
            // IE < 9
            document.selection.createRange().pasteHTML(curText);
        }
    }

    insertImage() {

    }

    onImageUpload(obj) {
        if (obj.base64Content) {
            qreq.post('/api/editor/upload/image', obj, j => {
                if (j.item) {
                    obj.id = j.item.id;
                    obj.ident = j.item.ident;
                }
                else if (j.errorCode) {
                    this.alert('Error uploading image: ' + j.errorMessage);
                }
            });
        }
    }

    edit() {
        HTMLEditorGlobal.show(this.state.col.body, v => {
            this.state.col.body = v;
            if(this.props.onBodyChange)
                this.props.onBodyChange(v);
        });
    }

    ai() {
        AIModalGlobal.show(this.state.col.body, v => {
            this.state.col.body = v;
            if(this.props.onBodyChange)
                this.props.onBodyChange(v);
        }, false);
    }

    toggleImgAI() {
        this.state.col.ai = !this.state.col.ai;
        this.setState({ col: this.state.col });
        this.info(this.state.col.ai ? <span className="text-success"><L>AI Image enabled.</L></span> : <span className="text-muted"><L>AI Image disabled.</L></span>);
    }

    render() {
        return <>
            <div className="wbp-toolbar">
                <div>
                    <div className="move" onMouseDown={(e) => this.focusMove(e, this.idDiv, this.state.col, this.hideToolbar)}></div>
                    <div className="row">
                        <div className="col text-left">
                            {this.state.col.com.editor === 'text' ? <>
                                <div className="btn-group">
                                    <button className="btn btn-light" onClick={() => this.edit()} title="Edit"><FontAwesomeIcon icon={fa.faPencil} /></button>
                                </div>
                                </> : ''}
                            {EditorGlobal.isTemplate && this.state.col?.com?.innerElement === 'img' && <div className="btn-group">
                                    <button className={'btn btn-light' + (this.state.col.ai ? ' text-success' : '')} onClick={() => this.toggleImgAI()} title="AI Prompt"><FontAwesomeIcon icon={fa.faBrain} /></button>
                                </div>}
                            {this.state.col.com.editor === 'menu' ? <div className="btn-group">
                                <button className="btn btn-light" onClick={() => this.insertMenu()} title="Add Menu Item"><FontAwesomeIcon icon={fa.faBars} /><FontAwesomeIcon icon={fa.faPlus} /></button>
                            </div> : ''}
                            {this.state.col.com.sitePageID ? <div className="btn-group">
                                <button className="btn btn-light" onClick={() => this.props.navigate('/editor/page/' + this.state.col.com.sitePageID)}><FontAwesomeIcon icon={fa.faPencil} /></button>
                            </div> : ''}
                            <div className="btn-group d-none">
                                <button className="btn btn-light" onClick={() => this.showComProps()} title="Properties"><FontAwesomeIcon icon={fa.faGears} /></button>
                            </div>
                            <div className="btn-group">
                                <button className="btn btn-light" onClick={() => this.removeCol()} title="Delete"><FontAwesomeIcon icon={fa.faTrash} /></button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>;
    }
}

export default withParamsAndNavigate(Toolbar);