import BaseCom from "./BaseCom";
import ShutterStockGallery from "./ShutterStockGallery";

export default class ShutterStockGalleryModal extends BaseCom {

    select = (e, com) => {
        console.log('selected', com);
        this.props.onSelect(com);
    }

    render() {
        return this.wrapModal(<div className="container-fluid">
            <ShutterStockGallery components={this.props.components} cols={4} onSelect={this.select} />
        </div>, 'Media Gallery', 'xl');
    }
}