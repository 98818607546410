import { Modal } from "react-bootstrap"
import BaseCom from "./BaseCom"
import { qreq } from "../shared/qrequest";

export default class AboutModal extends BaseCom {
    componentDidMount() {
        qreq.get('/api/version', j => {
            this.setState({ version: j.item });
        });
    }

    render() {
        return <Modal show={this.props.show} onHide={this.props.onHide} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>About Site Builder</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>A web-based tool for creating and editing websites.</p>
                <p>Developed exclusively for <strong><a href="http://taplocalnow.com" target="_blank" rel="noopener noreferrer">TapLocal Now</a></strong> and <strong><a href="https://banktechpr.com" target="_blank" rel="noopener noreferrer">BankTech PR</a></strong>.</p>
                <p>Powered by: <a href="https://solusek.com" target="_blank" rel="noopener noreferrer">Solusek Systems</a></p>
                <div className="d-flex justify-content-between">
                    <div>Release: v{this.state.version}</div>
                    <div className="text-muted">Solusek Builder</div>
                </div>
            </Modal.Body>
        </Modal>;
    }
}