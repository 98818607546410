import BaseCom from "./BaseCom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as fa from '@fortawesome/free-solid-svg-icons'
import LauncherModal from "./LauncherModal";
import TemplateModal from "./TemplateModal";
import Auth from "../shared/Auth";
import { Link } from "react-router-dom";
import Globals from "../shared/Globals";
import { qreq } from "../shared/qrequest";
import Icon from "./Icon";
import { withParamsAndNavigate } from "./WithParamsAndNavigate";
import L from "./Lang";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { getCurrentLangItem, getLanguages } from "../shared/GLang";
import { AppGlobal } from "../App";

class EditorHeader extends BaseCom {
    constructor(props) {
        super(props);
        this.state = {};
        this.preview = this.preview.bind(this);
        this.getBackLink = this.getBackLink.bind(this);
    }

    componentDidMount() {
        Auth.getLogin(u => {
            this.setState({ user: u });

            qreq.get('/api/settings/editor', j => {
                if (j.item)
                    this.setState({ settings: j.item });
            });

            qreq.get('/api/organization/get/current?siteDomainID=' + (this.props.params.templateID ?? '0'), j => {
                if (j.item)
                    this.setState({ organization: j.item });
            });
        });
    }

    preview() {
        if (this.props.domain) {
            if (this.state.settings.renderUrl)
                window.open(this.state.settings.renderUrl.replace('*', this.props.domain.masterID).replace('%', this.props.metadata.ident));
            else
                window.open('https://' + this.props.domain.masterID + '.' + window.location.host + '/' + this.props.metadata.ident);
        }
        else
            this.alert('No preview. Page might not be saved.');
    }

    getBackLink() {
        if(Auth.hasFlag('admin'))
            return '/';
        return this.state.organization ? this.state.organization.dashboardURL : '/index';
    }

    render() {
        return <>
            <TemplateModal show={this.state.showTemplateModal} onHide={() => this.setState({ showTemplateModal: false })} />

            {this.state.organization ? <iframe src={this.state.organization.dashboardURL} title="Dashboard Session Holder" className="d-none" style={{ display: 'none' }}></iframe> : null}

            <header className="header" id="header">

                <div className="header_toggle float-start">
                    <a href={this.getBackLink()} title="Back to Dashboard"><img src={Globals.imageUrl('/api/settings/logo')} style={{ maxWidth: '140px', maxHeight: '60px', marginLeft: '-60px' }} alt="" /></a> &nbsp;  <a href={this.getBackLink()} title="Back to Dashboard"><img src="/img/pointingchevs.svg" style={{ height: '16px' }} alt="back" /></a>
                </div>

                <div className="float-end d-none">
                    <a href={this.getBackLink()} title="Back" style={{ fontSize: '11px', color: '#000' }}><i className="fa-solid fa-caret-left fa-fade"></i> <L>Back to Dashboard</L></a> &nbsp;
                    <span role="button" onClick={e => { e.preventDefault(); this.setState({ showLauncherModal: true }); }} className="top-right-control" data-bs-toggle="modal" data-bs-target="#welcomemodal">Hi, <span className="top-right-heavy">{this.state.user ? this.state.user.name ?? this.state.user.email : ''}</span> &nbsp; <span style={{ fontSize: '20px', fontWeight: '600', padding: '7px 12px 7px 12px', color: '#0abb87', backgroundColor: 'rgb(10, 187, 135, 0.1)' }}>S</span></span>
                </div>

                <div className="float-end d-none">
                    <a href={this.getBackLink()} title="Back" style={{ fontSize: '11px', color: '#000' }}><i className="fa-solid fa-caret-left fa-fade"></i> <L>Back</L></a> &nbsp;
                    <span role="button" onClick={e => { e.preventDefault(); this.setState({ showLauncherModal: true }); }} className="top-right-control"><L>Hi,</L> <span className="top-right-heavy">{this.state.user ? this.state.user.name ?? this.state.user.email : ''}</span></span>
                </div>

                <div className="float-end d-flex gap-2">
                    <DropdownButton title={getCurrentLangItem()?.ident.toUpperCase()} size="lg" variant="secondary">
                        {getLanguages()?.map(l => <Dropdown.Item key={l.ident} onClick={() => AppGlobal.setLang(l.ident)}>{l.name} ({l.ident.toUpperCase()})</Dropdown.Item>)}
                    </DropdownButton>
                    <button type="button" className="btn" title="Undo" onClick={this.props.controlFunctions.undo} disabled={!this.props.controlFunctions.canUndo()}><Icon icon="faUndo" /></button>
                    <button type="button" className="btn" title="Redo" onClick={this.props.controlFunctions.redo} disabled={!this.props.controlFunctions.canRedo()}><Icon icon="faRedo" /></button>
                    <button type="button" className="btn btn-default" onClick={this.props.controlFunctions.showSave}><L>Save</L></button>
                    <button type="button" className="btn btn-default text-primary" onClick={this.preview}><L>Preview</L></button>
                    {!this.props.payment && !this.state.user?.isInternal && <button type="button" className="btn btn-success" onClick={this.props.controlFunctions.purchase}><Icon icon="faCreditCard" /> <L>Purchase</L></button>}
                    <button type="button" className="btn btn-primary" onClick={this.props.controlFunctions.publish}><Icon icon="faPaperPlane" /> <L>Publish</L></button>
                </div>

            </header>

            {this.props.domain ? <div className="header-preview-nav d-none">
                <div className="row">
                    <div className="col-md-12">
                        <div className="text-center">
                            <span role="button" title="Preview" onClick={this.preview}><FontAwesomeIcon icon={fa.faEye} /> <L>Page Preview</L></span>
                        </div>
                    </div>
                </div>

            </div> : ''}
        </>;
    }
}

export default withParamsAndNavigate(EditorHeader);