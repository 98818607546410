import BaseCom from "./BaseCom";
import Icon from "./Icon";
import L from "./Lang";
import { withParamsAndNavigate } from "./WithParamsAndNavigate";

class SelectTemplateMode extends BaseCom {

    render() {
        return <div className="container-fluid mt-2">
            <span className="float-end">
                <span onClick={this.props.onBack} role="button" className="fs-2 text-white" data-bs-dismiss="modal" aria-label="Close">
                    <Icon icon="faTimes" />
                </span>
            </span>
            <h1 className="text-center modal-header-weight mt-2 mb-3 text-shadow">
                <L>Welcome to Your Web Builder.</L>
            </h1>
            <div className="row">
                <div className="col-lg-1 col-md-0"></div>
                <div className="col-lg-10 col-md-12">
                    <div className="card custom-card3 mb-4">
                        <div className="card-body">
                            <h4 className="template-modal-card-title">
                                <L>Begin With A Blank Template</L>
                            </h4>
                            <div className="row">
                                <div className="col-lg-6 template-modal-text">
                                    <L>
                                        If you want to write all your content
                                        yourself, then we'll prepare your
                                        template with placeholder text that
                                        you can fill with your own words.
                                    </L>
                                </div>
                                <div className="col-lg-6 text-center">
                                    <div>
                                        <img src="/img/launcher02.png" className="img-fluid use-template-img" alt="with the pros" />
                                    </div>
                                </div>
                            </div>
                            <div className="d-none d-xl-block">
                                <button type="button" onClick={() => this.props.onSelect(this.props.tpl)} data-bs-toggle="modal" data-bs-target="#templatemodal" className="btn btn-success text-body"><L>Get Started</L></button>
                            </div>
                        </div>
                        <div className="card-footer d-xl-none">
                            <button type="button" onClick={() => this.props.setPage('template-ai')} data-bs-toggle="modal" data-bs-target="#templatemodal" className="btn btn-success btn-block text-body"><L>Get Started</L></button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-1 col-md-0"></div>
                <div className="col-lg-10 col-md-12">
                    <div className="card custom-card3 mb-4">
                        <div className="card-body">
                            <h4 className="template-modal-card-title">
                                <L>Have AI Write Content For Me</L>
                            </h4>
                            <div className="row">
                                <div className="col-lg-6 template-modal-text">
                                    <L>
                                        Rather have artificial intelligence
                                        generate your site's content? We'll
                                        ask a few questions to generate
                                        relevant copy that you can edit
                                        later.
                                    </L>
                                </div>
                                <div className="col-lg-6 text-center">
                                    <div>
                                        <img src="/img/launcher05.png" className="img-fluid use-ai-img" alt="Ai generation" />
                                    </div>
                                </div>
                            </div>
                            <div className="d-none d-xl-block">
                                <button type="button" onClick={() => this.props.setPage('template-ai')} className="btn btn-success text-body" data-bs-toggle="modal" data-bs-target="#aiinput"><L>Get Started</L></button>
                            </div>
                        </div>
                        <div className="card-footer d-xl-none">
                            <button type="button" onClick={() => this.props.setPage('template-ai')} className="btn btn-success text-body" data-bs-toggle="modal" data-bs-target="#aiinput"><L>Get Started</L></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>;
    }
}

export default withParamsAndNavigate(SelectTemplateMode);