import { qreq } from "../shared/qrequest";
import Alert from "./Alert";
import BaseCom from "./BaseCom";
import FormInput from "./FormInput";
import Icon from "./Icon";
import L from "./Lang";
import LoadingAlert from "./LoadingAlert";
import { withParamsAndNavigate } from "./WithParamsAndNavigate";

class AISurvey extends BaseCom {

    constructor(props) {
        super(props);
        this.state = {
            info: {
                address: {}
            },
            categories: []
        };
        this.submit = this.submit.bind(this);
    }

    componentDidMount() {
        qreq.get('/api/category/list', j => {
            if(j.list)
                this.setState({ categories: j.list });
        });
        qreq.get('/api/merchant/my', j => {
            if(j.item)
                this.setState({ info: j.item });
        });
    }

    submit(e) {
        e?.preventDefault();
        const categoryName = this.state.categories.find(a => a.id ===  this.state.info.categoryID).name;
        this.setState({ submitting: true });
        var data = {
            name: this.state.info.name,
            subType: categoryName,
            payload: JSON.stringify({
                name: this.state.info.name,
                email: this.state.info.email,
                type: categoryName,
                description: this.state.info.description,
                templateID: this.props.tpl.id,
                address: this.state.info.address.line1 + ' ' + this.state.info.address.line2 + ', ' + this.state.info.address.city + ', ' + this.state.info.address.state + ' ' + this.state.info.address.postalCode,
                phone: this.state.info.phone,
                products: [
                    this.state.info.product1,
                    this.state.info.product2,
                    this.state.info.product3
                ],
                addressCityStateOnly: this.state.info.addressCityStateOnly
            })
        }
        qreq.post('/api/ai/generate?siteDomainID=' + (this.props.params.templateID ?? '0'), data, j => {
            if (j.errorCode)
                this.alert(j.errorMessage);
            else {
                this.setState({ processing: true });
                this.poll(j.item);
            }
            this.setState({ submitting: false });
        }, () => {
            this.alert('Unkown error ocurred. Please try again.');
            this.setState({ submitting: false });
        });
    }

    poll(task) {
        qreq.get('/api/ai/status?taskID=' + task.id, j => {
            if (j.errorCode) {
                this.alert(j.errorMessage);
            }
            else {
                if (j.item._StatusResult === 1) {
                    setTimeout(() => this.poll(task), 1000);
                }
                else if (j.item._StatusResult === 0) {
                    this.setState({ success: true, processing: false, indexID: Number(j.item.result) });
                    window.location.reload();
                    //this.props.navigate('/editor/page/' + Number(j.item.result));
                    //this.props.onHide();
                }
                else {
                    this.setState({ error: j.item.result, processing: false });
                    setTimeout(() => {
                        this.setState({ error: null });
                    }, 5000);
                }
            }
        }, () => {
            this.setState({ error: 'Unknown error. Please try again.', processing: false });
        });
    }

    render() {

        return <div className="container-fluid">
            <span className="float-end">
                <span onClick={this.props.onBack} role="button" className="fs-2 text-white" data-bs-dismiss="modal" aria-label="Close">
                    <Icon icon="faTimes" />
                </span>
            </span>
            <h1 className="text-center modal-header-weight mt-2 mb-3 text-shadow">
                <L>Let AI Build your Website</L>
            </h1>
            <div className="bg-light p-3 rounded">
                {this.state.success ? <Alert type="success"><L>Completed!</L></Alert> : this.state.error ? <Alert type="danger">{this.state.error}</Alert> : this.state.processing ? <Alert type="warning"><L>Processing please wait...</L></Alert> : this.state.submitting ? <LoadingAlert /> :
                    <form onSubmit={this.submit}>
                        <h5 className="text-primary"><L>Business</L></h5>

                        <div className="row">
                            <div className="col-md">
                                <FormInput type="text" model={this.state.info} name="name" label="Business Name" placeholder="Bob's Burgers" required />
                            </div>
                            <div className="col-md">
                                <FormInput type="select-search" model={this.state.info} name="categoryID" label="Industry" required options={this.state.categories} optionsName="name" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md">
                                <FormInput type="text" model={this.state.info} name="email" label="Business Email" placeholder="info@example.net" required />
                            </div>
                            <div className="col-md">
                                <FormInput type="text" model={this.state.info} name="phone" label="Business Phone #" placeholder="000 000 0000" required />
                            </div>
                        </div>

                        <h5 className="text-primary"><L>Address</L></h5>
                        
                        <FormInput type="text" model={this.state.info.address} name="line1" label="Address Line 1" placeholder="Test St. 123" required />
                        <FormInput type="text" model={this.state.info.address} name="line2" label="Address Line 2" placeholder="Suite #123" />

                        <div className="row">
                            <div className="col-md">
                                <FormInput type="text" model={this.state.info.address} name="city" label="City" placeholder="San Juan" required />
                            </div>
                            <div className="col-md">
                                <FormInput type="text" model={this.state.info.address} name="state" label="State" placeholder="PR" required />
                            </div>
                            <div className="col-md">
                                <FormInput type="text" model={this.state.info.address} name="postalCode" label="Postal Code" placeholder="00926" required />
                            </div>
                        </div>

                        <h5 className="text-primary"><L>Products/Services</L></h5>
                        
                        <FormInput type="text" model={this.state.info} name="product1" label="Product/Service #1" placeholder="My Product/Service 1" />
                        <FormInput type="text" model={this.state.info} name="product2" label="Product/Service #2" placeholder="My Product/Service 2" />
                        <FormInput type="text" model={this.state.info} name="product3" label="Product/Service #3" placeholder="My Product/Service 3" />

                        <h5 className="text-primary"><L>Additional Information</L></h5>

                        <FormInput type="textarea" model={this.state.info} name="description" label="Business Description" placeholder="A short description about your business..." />
                        <div className="mt-2 d-grid gap-2">
                            <button type="submit" className="btn btn-success btn-lg"><L>Generate!</L></button>
                        </div>
                        <div className="mt-3 text-center">
                            <small role="button" onClick={this.props.onTemplateOnly} className="text-muted"><L>Click here if you would rather use the template without AI.</L></small>
                        </div>
                    </form>}
            </div>
        </div>;
    }
}

export default withParamsAndNavigate(AISurvey);