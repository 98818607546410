import Globals from "../shared/Globals";
import { qreq } from "../shared/qrequest";
import BaseCom from "./BaseCom";
import Card from "./Card";
import { withParamsAndNavigate } from "./WithParamsAndNavigate";

class PurchaseCompleteModal extends BaseCom {
    componentDidMount() {
        qreq.get('/api/domain/payment/check', j => {
            if (j.item)
                this.setState({ ok: true, item: j.item });
            else
                this.setState({ errorMessage: j.errorMessage });
        }, () => this.setState({ errorMessage: 'Unknown error. Please contact support.' }));
    }
    render() {
        if (!this.state.ok && !this.state.errorMessage) {
            return <div className="container">
                <Card>
                    <h1>Checking Purchase...</h1>
                    <p>Please wait while we check your purchase...</p>
                </Card>
            </div>;
        }

        if (this.state.errorMessage) {
            return <div className="container">
                <Card>
                    <h1>Error</h1>
                    <p>{this.state.errorMessage}</p>
                    <div className="text-center">
                        <button className="btn btn-primary btn-lg" onClick={() => this.props.navigate('/editor')}>Go Back to Editor</button>
                    </div>
                </Card>
            </div>;
        }

        return <div className="container">
            <Card>
                <h1>Purchase Complete</h1>
                <p>Your purchase is complete. Payment processed on {Globals.toMDYHMLocalDateString(this.state.item)} You will receive an email notification when your website is fully live.</p>
                <div className="text-center">
                    <button className="btn btn-primary btn-lg" onClick={() => this.props.navigate('/editor')}>Go Back to Editor</button>
                </div>
            </Card>
        </div>;
    }
}

export default withParamsAndNavigate(PurchaseCompleteModal);