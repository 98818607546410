import Globals from "../shared/Globals";
import { qreq } from "../shared/qrequest";
import Alert from "./Alert";
import BaseCom from "./BaseCom";
import Card from "./Card";
import Icon from "./Icon";
import L from "./Lang";
import LoadingAlert from "./LoadingAlert";
import { withParamsAndNavigate } from "./WithParamsAndNavigate";


class SelectTemplate extends BaseCom {
    constructor(props) {
        super(props);
        this.state = {
            list: null
        };
        this.selectTemplate = this.selectTemplate.bind(this);
    }

    componentDidMount() {
        qreq.get('/api/template/list', j => {
            if (j.list)
                this.setState({ list: j.list, loaded: true });
            else
                this.alert(j.errorMessage);
        }, this.unkownErrorCallback);
    }

    selectTemplate(obj) {
        this.props.onSelect(obj);
    }

    getTplElement(target) {
        var obj = target;

        while (obj.className.indexOf('c-tpl') !== -1) {
            if (!obj.parentElement)
                return null;
            obj = obj.parentElement;
        }

        return obj;
    }

    render() {
        return <div className="container-fluid">
            <div className="d-flex align-items-center">
                <h1 className="mt-1 mb-3 modal-header-weight text-shadow"><L>Select a base template</L></h1>
                <div className="flex-fill text-end">
                    <span onClick={this.props.onBack} role="button" className="fs-2 text-white" data-bs-dismiss="modal" aria-label="Close">
                        <Icon icon="faTimes" />
                    </span>
                </div>
            </div>
            <div className="bg-light p-3 rounded">
                {!this.state.list ? <LoadingAlert /> : this.state.list.length === 0 ? <Alert type="warning"><L>There are no templates in the system.</L></Alert> : <div class="row ml-2 mr-2">
                    {this.state.list.map(obj => <div class="col-lg-4 col-sm-6 col-12 p-4">
                        <div class="card text-center">
                            <div class="card-body">
                                <img src={Globals.imageUrl('/api/media/site/preview/' + obj.masterID)} class="card-img-top img-fluid" style={{borderRadius: '12px'}} alt="..." />
                                <div className="my-1">{obj.templateName}</div>
                                <div>
                                    <button class="btn btn-primary d-none" disabled={this.state.selecting} data-bs-toggle="modal" data-bs-target="#website1"><L>Quick View</L></button>
                                    <button class="btn btn-outline-primary" disabled={this.state.selecting} onClick={() => this.selectTemplate(obj)}><L>Select Template</L></button>
                                </div>                                
                            </div>
                        </div>
                    </div>)}
                </div>}
            </div>
        </div>;
    }
}

export default withParamsAndNavigate(SelectTemplate);