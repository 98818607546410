import { Modal } from "react-bootstrap";
import BaseCom from "./BaseCom";
import FormInput from "./FormInput";
import { qreq } from "../shared/qrequest";
import Globals from "../shared/Globals";
import { EditorGlobal } from "./Editor";

export const HTMLEditorLinkGlobal = {
    init(showFunction, hideFunction) {
        this.show = showFunction;
        this.hide = hideFunction;
    },

    show(url, onSave, text) {
        //overriden on init
    },

    hide() {
        //overriden on init
    }
};

export default class HTMLEditorLink extends BaseCom {
    constructor(props) {
        super(props);
        this.state = {
            info: {},
            pages: []
        };
        this.load = this.load.bind(this);
        this.show = this.show.bind(this);
        this.hide = this.hide.bind(this);
        this.shown = this.shown.bind(this);
        this.submit = this.submit.bind(this);
        HTMLEditorLinkGlobal.init(this.show, this.hide);
    }

    componentDidMount() {
        this.load();
    }

    load(callback) {
        if(!EditorGlobal.siteDomainID)
            return;
        this.setState({ loading: true });
        qreq.get('/api/editor/page/list?siteDomainID=' + EditorGlobal.siteDomainID, j => {
            if(j.list) {
                this.setState({ pages: j.list, loading: false });
                if(j.list.length === 0)
                    this.setState({ useURL: true });
                callback?.(j.list);
            }
        });
    }

    show(url, onSave, text) {
        this.setState({ info: { url: url, text: text }, showModal: true, onSave: onSave });
        this.load(pages => this.shown(url, pages, text));
    }

    hide() {
        this.setState({ info: {}, showModal: false, onSave: null });
    }

    shown(url, pages, text) {
        var info = { text: text };
        if(url) {
            url = url.replace(/"/g, '').replace(/\.\.\//g, '').replace(/\.\//g, '');
            if(url.startsWith('http://') || url.startsWith('https://') || url.startsWith('mailto:'))
                this.setState({ useURL: true });
            else if(this.state.pages) {
                if(url.length > 1) {
                    if(url.substring(0,1) === '/')
                        url = url.substring(1);
                    var find = pages.find(p => p.ident === url);
                    if(find) {
                        info.pageID = find.id;
                        this.setState({ useURL: false });
                    }
                    else
                        this.setState({ useURL: true });
                }
            }
        }
        info.url = url;
        this.setState({ info: info });
    }

    submit(e) {
        e.preventDefault();
        var info = { ...this.state.info };
        if(!this.state.useURL) {
            if(!info.pageID)
                info.url = '';
            else {
                info.pageID = parseInt(info.pageID);
                var page = this.state.pages.find(p => p.id === info.pageID);
                if(page)
                    info.url = '/' + page.ident;
            }
        }
        else {
            if(!info.url)
                info.url = '';
            else if(info.url.length > 1 && !info.url.startsWith('http://') && !info.url.startsWith('https://') && !info.url.startsWith('mailto:')) {
                if(info.url.indexOf('@') > -1)
                    info.url = 'mailto:' + info.url;
                else
                    info.url = 'https://' + info.url;
            }
        }

        info.url = info.url.replace(/"/g, '');
        this.state.onSave(info.url, info.text);
        this.hide();
    }

    render() {
        return <Modal show={this.state.showModal} onHide={() => this.setState({ showModal: false })}>
            <Modal.Header closeButton>
                <Modal.Title>Insert Link</Modal.Title>
            </Modal.Header>
            <form onSubmit={this.submit}>
                <Modal.Body>
                    <FormInput model={this.state.info} name="text" label="Text" type="text" />
                    {!this.state.useURL ? <>
                    <FormInput model={this.state.info} name="pageID" label="Page" type="select" options={this.state.pages} />
                    <small role="button" className="d-block text-primary" onClick={() => this.setState({ useURL: true })}>Use a URL instead</small>
                    </> : null}
                    {this.state.useURL ? <>
                    <FormInput model={this.state.info} name="url" label="URL" type="text" />
                    {this.state.pages.length > 0 ? <small role="button" className="d-block text-primary" onClick={() => this.setState({ useURL: false })}>Use a page instead</small> : null}
                    </> : null}
                </Modal.Body>
                <Modal.Footer>
                    <button type="submit" className="btn btn-primary">Insert</button>
                </Modal.Footer>
            </form>
        </Modal>
    }
}