import * as React from "react";
import { Outlet } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import Topbar from "../components/Topbar";
import { qreq } from "../shared/qrequest";
import Icon from "../components/Icon";
import { Pop } from "../components/PopModal";
import Auth from "../shared/Auth";
import Tick from "../components/Tick";

export default class Portal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            staging: null
        };
    }

    componentDidMount() {
        qreq.get('/api/status/staging', j => {
            if (j.item)
                this.setState({ staging: j.item })
        });
        this.tick();
    }

    tick = () => {
        Auth.getLogin(u => {
            if(u) {
                if (this.state.user?.version && u.version && u.version !== this.state.user?.version) {
                    Pop.showConfirm(<>The system version has changed from <b>{this.state.user.version}</b> to <b>{u.version}</b>. Please <i>Confirm</i> to refresh and view the newest version.</>, 'New Version', () => {
                        window.location.reload(true);
                    });
                }
            }
            this.setState({ user: u });
        }, null, true);
    }

    render() {
        return <>
            <Tick onTick={this.tick} interval={15000} />
            {this.state.staging && <section className="staging">
                <div className="container">
                    <span role="button" onClick={() => this.setState({ staging: null })}><Icon icon="faClose" /> This is a staging instance of the builder. Click to hide this message.</span>
                </div>
            </section>}
            <main className={this.state.staging ? 'main-staging' : ''}>
                <div className="c-pagewrapper">
                    <Outlet />
                </div>
            </main>
        </>;
    }
}