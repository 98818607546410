import React from "react";
import BaseCom from "./BaseCom";
import { v4 as uuidv4 } from 'uuid';
import tinymce from 'tinymce/tinymce';
import 'tinymce/icons/default';
import 'tinymce/models/dom';
import 'tinymce/themes/silver/theme';
import 'tinymce/skins/ui/oxide/skin.min.css';
import Globals from "../shared/Globals";
import { AIModalGlobal } from "./AIModal";
import { HTMLEditorLinkGlobal } from "./HTMLEditorLink";

class HTMLEditor extends BaseCom {
    constructor(props) {
        super(props);
        this.state = {
            id: 't' + uuidv4().replace(/-/g, ''),
            value: this.props.defaultValue ?? this.props.value
        };
    }

    componentDidMount() {
        var ele = document.getElementById(this.state.id);
        if (ele) {
            var tox = ele.parentNode.getElementsByClassName('tox');
            if (tox.length === 0) {
                tinymce.init({
                    selector: 'textarea#' + this.state.id,
                    statusbar: false,
                    promotion: false,
                    menubar: false,
                    toolbar: 'ai customLink customButton | bold italic underline | styles fontfamily fontsize | forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat',
                    content_style: `
                        .btn-primary {
                            background-color: #0d6efd;
                            display: inline-block;
                            color: #ffffff;
                            padding: 8px 12px;
                            border-radius: 5px;
                            text-decoration: none;
                        }
                    `,
                    setup: ed => {
                        ed.on('change', e => {
                            this.setState({ value: ed.getContent() });
                            if (this.props.onChange)
                                this.props.onChange(ed.getContent());
                        });
                        ed.ui.registry.addIcon('button', '<svg width="24" height="24" viewBox="0 0 987 284">  <defs>    <style>      .cls-1 {        fill: #000;      }      .cls-1, .cls-2 {        stroke-width: 0px;      }      .cls-2 {        fill: #fff;      }    </style>  </defs>  <g>    <rect class="cls-2" x="20" y="20" width="947" height="244"/>    <path class="cls-1" d="M947,40v204H40V40h907M987,0H0v284h987V0h0Z"/>  </g>  <g>    <path class="cls-1" d="M179.96,164.71c-12.11,0-21.96-10.25-21.96-22.86s9.85-22.86,21.96-22.86,21.96,10.25,21.96,22.86-9.85,22.86-21.96,22.86Z"/>    <path class="cls-1" d="M179.96,124c9.35,0,16.96,8.01,16.96,17.86s-7.61,17.86-16.96,17.86-16.96-8.01-16.96-17.86,7.61-17.86,16.96-17.86M179.96,114c-14.89,0-26.96,12.47-26.96,27.86s12.07,27.86,26.96,27.86,26.96-12.47,26.96-27.86-12.07-27.86-26.96-27.86h0Z"/>  </g>  <g>    <path class="cls-1" d="M493.08,165c-12.11,0-21.96-10.25-21.96-22.86s9.85-22.86,21.96-22.86,21.96,10.25,21.96,22.86-9.85,22.86-21.96,22.86Z"/>    <path class="cls-1" d="M493.08,124.29c9.35,0,16.96,8.01,16.96,17.86s-7.61,17.86-16.96,17.86-16.96-8.01-16.96-17.86,7.61-17.86,16.96-17.86M493.08,114.29c-14.89,0-26.96,12.47-26.96,27.86s12.07,27.86,26.96,27.86,26.96-12.47,26.96-27.86-12.07-27.86-26.96-27.86h0Z"/>  </g>  <g>    <path class="cls-1" d="M807.04,165c-12.11,0-21.96-10.25-21.96-22.86s9.85-22.86,21.96-22.86,21.96,10.25,21.96,22.86-9.85,22.86-21.96,22.86Z"/>    <path class="cls-1" d="M807.04,124.29c9.35,0,16.96,8.01,16.96,17.86s-7.61,17.86-16.96,17.86-16.96-8.01-16.96-17.86,7.61-17.86,16.96-17.86M807.04,114.29c-14.89,0-26.96,12.47-26.96,27.86s12.07,27.86,26.96,27.86,26.96-12.47,26.96-27.86-12.07-27.86-26.96-27.86h0Z"/>  </g></svg>');
                        ed.ui.registry.addButton('AI', {
                            icon: 'AI',
                            onAction: () => {
                                AIModalGlobal.show(null, v => {
                                    ed.insertContent(v);
                                }, !Globals.get('isTemplate'));
                            }
                        });
                        ed.ui.registry.addButton('customButton', {
                            icon: 'button',
                            onAction: () => {
                                var url = null;
                                var preSel = ed.selection.getContent({ format: 'html' });
                                var match = preSel.match(/<a href="([^"]+)">([^<]+)<\/a>/);
                                if(match) {
                                    if(match.length >= 2)
                                        url = match[1];
                                }
                                HTMLEditorLinkGlobal.show(url, (v, text) => {
                                    var sel = text;
                                    if(!v)
                                        ed.insertContent(sel);
                                    else
                                        ed.insertContent('<a href="' + v + '"" class="btn btn-primary">' + sel + '</a>')
                                }, ed.selection.getContent({ format: 'text' }));
                            }
                        });
                        ed.ui.registry.addButton('customLink', {
                            icon: 'link',
                            onAction: () => {
                                var url = null;
                                var preSel = ed.selection.getContent({ format: 'html' });
                                var match = preSel.match(/<a href="([^"]+)">([^<]+)<\/a>/);
                                if(match) {
                                    if(match.length >= 2)
                                        url = match[1];
                                }
                                HTMLEditorLinkGlobal.show(url, (v,text) => {
                                    var sel = text;
                                    if(!v)
                                        ed.insertContent(sel);
                                    else
                                        ed.insertContent('<a href="' + v + '"">' + sel+ '</a>')
                                }, ed.selection.getContent({ format: 'text' }));
                            }
                        });
                    }
                });
            }
            else {
                ele.style.display = 'none';
            }
        }
    }

    componentWillUnmount() {
        tinymce.get(this.state.id).remove();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.defaultValue !== this.props.defaultValue)
            this.setState({ value: this.props.defaultValue });
        if (prevProps.value !== this.props.value)
            this.setState({ value: this.props.value });
    }

    render() {
        return <div id={this.state.id + '-wrapper'}>
            <textarea id={this.state.id} defaultValue={this.props.defaultValue ?? this.props.value}></textarea>
        </div>;
    }
}

export default HTMLEditor;