import React from 'react';
import BaseCom from './BaseCom';
import LoadingAlert from './LoadingAlert';
import { qreq } from '../shared/qrequest';
import DataTable from './DataTable';
import { withParamsAndNavigate } from './WithParamsAndNavigate';
import Alert from './Alert';
import AdminLayout from './AdminLayout';
import { Link } from 'react-router-dom';
import Icon from './Icon';
import { Pop } from './PopModal';
import Card from './Card';

class AdminMerchants extends BaseCom {

    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            user: null,
            list: [],
            loading: true,
            loaded: false
        };
        this.listRef = React.createRef();
        this.load = this.load.bind(this);
        this.scrollHandler = this.scrollHandler.bind(this);
    }

    componentDidMount() {
        qreq.get('/api/settings/editor', j => {
            if(j.item)
                this.setState({ settings: j.item });
        });
        this.load();
        document.addEventListener('scroll', this.scrollHandler);
    }

    componentWillUnmount() {
        document.removeEventListener('scroll', this.scrollHandler);
    }

    load() {
        qreq.get('/api/admin/merchant/list', j => {
            if(j.list) {
                this.setState({ list: j.list, loaded: true, loading: false });
                if(j.list.length > 0)
                    this.scrollHandler();
            }
            else
                this.alert(j.errorMessage);
        }, this.unkownErrorCallback);
    }

    scrollHandler() {
        if (!this.listRef.current)
            return;
        const bottom = this.listRef.current.getBoundingClientRect().bottom - 50 <= window.innerHeight;
        if (bottom) {
            if (this.state.loading || this.state.endOfList)
                return;
            this.setState({ loading: true });
            qreq.get('/api/admin/merchant/list?page=' + (this.state.page+1), j => {
                if (j.list) {
                    if (j.list.length === 0)
                        this.setState({ endOfList: true });
                    else {
                        var list = [...this.state.list];
                        list = list.concat(j.list);
                        this.setState({ list: list, page: this.state.page + 1 });
                    }
                }
                else
                    this.alert(j.errorMessage);
                this.setState({ loading: false });

            }, () => {
                this.setState({ loading: false });
                this.unkownErrorCallback();
            });
        }
    }


    render() {

        if (!this.state.loaded)
            return (<div className="container-fluid"><LoadingAlert /></div>);

        if (this.state.errorMessage)
            return <Alert type="danger" icon="ExclamationTriangle" message={this.state.errorMessage} />


        return <AdminLayout>
            <div className="container-fluid">
                <Card title="All Merchant">
                    <DataTable tableRef={this.listRef} columns={[
                        { icon: 'Eye', link: '/admin/merchants/edit/:id', tooltip: 'View Merchant' },
                        { name: 'merchantID', title: 'MID', link: '/admin/merchants/edit/:id', tooltip: 'View Merchant', className: 'd-none d-md-table-cell' },
                        { name: 'name', title: 'Name', link: '/admin/merchants/edit/:id', tooltip: 'View Merchant' },
                        { name: 'createdDate', title: 'Created', format: 'datetime', className: 'd-none d-md-table-cell' }
                    ]} data={this.state.list} loading={this.state.loading} />
                </Card>
            </div>
        </AdminLayout>;
    }
}

export default withParamsAndNavigate(AdminMerchants);