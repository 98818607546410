import * as React from "react";
import Dropdown from 'react-bootstrap/Dropdown';
import Auth from "../shared/Auth";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as fa from '@fortawesome/free-solid-svg-icons'
import { NavLink, Link } from "react-router-dom";
import { withParamsAndNavigate } from "./WithParamsAndNavigate";
import { Tooltip } from 'react-tooltip';
import SitePages from "./SitePages";
import ConfirmDialog from "./ConfirmDialog";
import { qreq } from "../shared/qrequest";
import PropertiesEditor from "./PropertiesEditor";
import ShutterStockGallery from "./ShutterStockGallery";
import Alert from "./Alert";
import ComPreview from "./ComPreview";
import LoadingAlert from "./LoadingAlert";
import Components from "./Components";
import AboutModal from "./AboutModal";
import NavEditorModal from "./NavEditorModal";
import L from "./Lang";

class NavItem extends React.Component {
    constructor(props) {
        super(props);
        this.label = props.label;
        this.href = props.href;
        this.icon = props.icon;
    }

    render() {
        return (<li className="nav-item">
            <NavLink to={this.href} className="nav-link">
                {this.icon ? (<FontAwesomeIcon icon={this.icon} className="me-1" />) : ''}
                <span>{this.label}</span>
            </NavLink>
        </li>);

    }
}

class NavHeader extends React.Component {
    constructor(props) {
        super(props);
        this.label = props.label;
    }

    render() {
        return (<div className="nav-header text-uppercase p-3 m-1 mb-2 pb-2 border-bottom border-light">{this.label}</div>);
    }
}

class NavGroup extends React.Component {
    constructor(props) {
        super(props);
        this.children = props.children;
    }

    render() {
        return (<ul className="nav nav-pills flex-column">
            {this.children}
        </ul>);

    }
}

class EditorSidebar extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            user: props.user,
            toggle: null,
            parentToggle: props.toggle,
            components: props.components,
            metadata: props.metadata,
            col: props.col,
            container: props.container
        }

        this.focusMove = props.focusMove;
        this.setToggle = this.setToggle.bind(this);
        this.closeOffCanvas = this.closeOffCanvas.bind(this);
        this.setSidebarExpanded = props.setSidebarExpanded;
        this.containers = props.containers;
        this.controlFunctions = props.controlFunctions;
        this.status = props.toolbarStatus;
        this.logout = this.logout.bind(this);
        this.onLogout = this.onLogout.bind(this);
        this.setMetadata = props.setMetadata;
        this.setSelectedColumn = props.setSelectedColumn;
        this.setComPropsToggle = props.setComPropsToggle;
    }

    componentDidMount() {
        Auth.getLogin(u => {
            this.setState({ user: u });
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.user !== this.props.user)
            this.setState({ user: this.props.user });
        if (prevProps.components !== this.props.components)
            this.setState({ components: this.props.components });
        if (prevProps.metadata !== this.props.metadata)
            this.setState({ metadata: this.props.metadata });
        if (prevProps.parentToggle !== this.props.parentToggle) {
            this.setState({ parentToggle: this.props.parentToggle });
            this.props.setSidebarExpanded(this.state.toggle || this.props.parentToggle);
        }
        if (prevProps.col !== this.props.col) {
            if (this.props.col)
                this.setState({ col: this.props.col });
            else if (!this.state.parentToggle)
                this.setState({ col: this.props.col });
        }
        if (prevProps.container !== this.props.container) {
            if (this.props.container)
                this.setState({ container: this.props.container });
            else if (!this.state.parentToggle)
                this.setState({ container: this.props.container });
        }
    }

    setToggle(v, show) {
        this.setSelectedColumn(null);
        this.setComPropsToggle(null);
        if (this.state.toggle === v && !show) {
            this.setState({ toggle: null });
            this.props.setSidebarExpanded(false);
        }
        else {
            this.setState({ toggle: v, parentToggle: null });
            this.props.setSidebarExpanded(v);
        }
    }

    closeOffCanvas() {
        this.setToggle(null);
        this.setSelectedColumn(null);
        this.setComPropsToggle(null);
        this.setState({ col: null });
    }

    onLogout(e) {
        e.preventDefault();
        this.confirmAction = this.logout;
        this.setState({ confirmMessage: 'Are you sure you want to logout?' })
    }

    logout() {
        qreq.post('/api/auth/logout', j => {
            if (!j.errorCode)
                document.location.assign('/');
        });
    }

    render() {

        return (<>

            <Tooltip anchorSelect={'.tt-com'} place="right" style={{ zIndex: 1999 }}>
                Elements
            </Tooltip>
            <Tooltip anchorSelect={'.tt-sec'} place="right" style={{ zIndex: 1999 }}>
                Components
            </Tooltip>
            <Tooltip anchorSelect={'.tt-pages'} place="right" style={{ zIndex: 1999 }}>
                Pages
            </Tooltip>
            <Tooltip anchorSelect={'.tt-design'} place="right" style={{ zIndex: 1999 }}>
                Design
            </Tooltip>
            <Tooltip anchorSelect={'.tt-mod'} place="right" style={{ zIndex: 1999 }}>
                Modules
            </Tooltip>
            <Tooltip anchorSelect={'.tt-media'} place="right" style={{ zIndex: 1999 }}>
                Media
            </Tooltip>
            <Tooltip anchorSelect={'.tt-props'} place="right" style={{ zIndex: 1999 }}>
                Properties
            </Tooltip>
            <Tooltip anchorSelect={'.tt-save'} place="right" style={{ zIndex: 1999 }}>
                Save
            </Tooltip>
            <Tooltip anchorSelect={'.tt-back'} place="right" style={{ zIndex: 1999 }}>
                Back to Page List
            </Tooltip>
            <Tooltip anchorSelect={'.tt-ai'} place="right" style={{ zIndex: 1999 }}>
                AI
            </Tooltip>

            <AboutModal show={this.state.showAbout} onHide={() => this.setState({ showAbout: false })} />
            <NavEditorModal show={this.state.showNavEditor} onHide={() => this.setState({ showNavEditor: false })} onSave={this.props.onNavSave} />
            <ConfirmDialog show={this.state.confirmMessage} title="Confirm" message={this.state.confirmMessage} onConfirm={() => this.confirmAction()} onHide={() => this.setState({ confirmMessage: null })} />

            <div className="l-navbar" id="nav-bar">
                <nav className="nav nav_list text-center">

                    <div>

                        <span role="button" className={'nav_link tt-com ' + (this.state.toggle === 'com' ? ' active' : '')} onClick={() => this.setToggle('com', true)}>
                            <div className="left-menu-box">
                                <FontAwesomeIcon icon={fa.faSquarePlus} />
                                <span className="nav_name"><L>Elements</L></span>
                            </div>
                        </span>

                        <div className="editorMenuSubtxt"><L>Elements</L></div>
                    </div>
                    <div>
                        <span role="button" className={'nav_link tt-design ' + (this.state.toggle === 'design' ? ' active' : '')} onClick={() => this.setToggle('design', true)}>
                            <div className="left-menu-box">
                                <FontAwesomeIcon icon={fa.faBook} />
                                <span className="nav_name"><L>Design</L></span>
                            </div>
                        </span>

                        <div className="editorMenuSubtxt"><L>Design</L></div>
                    </div>

                    <div>
                        <span role="button" className={'nav_link tt-pages ' + (this.state.toggle === 'pages' ? ' active' : '')} onClick={() => this.setToggle('pages', true)}>
                            <div className="left-menu-box">
                                <FontAwesomeIcon icon={fa.faWindowRestore} />
                                <span className="nav_name"><L>Pages</L></span>
                            </div>
                        </span>
                        <div className="editorMenuSubtxt"><L>Pages</L></div>
                    </div>

                    <div>
                        <span role="button" className={'nav_link tt-media ' + (this.state.toggle === 'media' ? ' active' : '')} onClick={() => this.setToggle('media', true)}>
                            <div className="left-menu-box">
                                <FontAwesomeIcon icon={fa.faImage} />
                                <span className="nav_name"><L>Media</L></span>
                            </div>
                        </span>
                        <div className="editorMenuSubtxt"><L>Media</L></div>
                    </div>

                    <div>
                        <span role="button" className={'nav_link tt-nav ' + (this.state.toggle === 'nav' ? ' active' : '')} onClick={() => this.setState({ showNavEditor: true })}>
                            <div className="left-menu-box">
                                <FontAwesomeIcon icon={fa.faNavicon} />
                                <span className="nav_name"><L>Nav</L></span>
                            </div>
                        </span>
                        <div className="editorMenuSubtxt"><L>Nav</L></div>
                    </div>

                    <div className="mt-auto" style={{marginBottom: '80px'}}>
                        <span role="button" className={'nav_link tt-help ' + (this.state.toggle === 'about' ? ' active' : '')} onClick={() => this.setState({ showAbout: true })}>
                            <div className="left-menu-box">
                                <FontAwesomeIcon icon={fa.faQuestionCircle} />
                                <span className="nav_name"><L>About</L></span>
                            </div>
                        </span>
                    </div>

                </nav>
            </div>

            <div className={'offcanvas offcanvas-end shadow' + (this.state.toggle || this.state.parentToggle ? ' show' : '')} tabIndex="-1" id="offcanvasRight" data-bs-scroll="true" data-bs-backdrop="false" aria-labelledby="offcanvasRightLabel">
                <div className="offcanvas-header">
                    <h5 id="offcanvasRightLabel">
                        {this.state.toggle === 'com' ? <L>Elements</L>: ''}
                        {this.state.toggle === 'sec' ? <L>Components</L> : ''}
                        {this.state.toggle === 'pages' ? <L>Pages</L> : ''}
                        {this.state.toggle === 'design' ? <L>Design</L> : ''}
                        {this.state.toggle === 'media' ? <L>Media</L> : ''}
                        {this.state.parentToggle === 'design' && this.state.col && this.state.col.com ? <><L>Properties:</L> {this.state.col.com.name}</> : ''}
                    </h5>
                    <button type="" className="btn-close-offcanvas" data-bs-dismiss="offcanvas" aria-label="Close" onClick={() => this.closeOffCanvas()}>x</button>
                </div>
                <div className="offcanvas-body">
                    {!this.state.parentToggle ? <>
                        {this.state.toggle === 'com' || this.state.toggle === 'sec' ?
                            <div className="p-4">
                                <div className="row">
                                    {this.props.componentsLoaded ? <Components components={this.state.components} focusMove={this.props.focusMove} toggle={this.state.toggle} /> : <LoadingAlert />}
                                </div></div> : ''}
                        {this.state.toggle === 'pages' ? <div className="p-3"><SitePages template={this.props.template} /></div> : ''}
                    </> : ''}
                    {this.state.toggle === 'design' || this.state.parentToggle === 'design' ? <PropertiesEditor col={this.state.col ? this.state.col : this.state.metadata.props} container={this.state.container} pageProps={this.state.col ? false : true} onChange={() => this.setMetadata(this.state.metadata)} components={this.state.components} /> : ''}
                    {this.state.toggle === 'media' && this.state.components && !this.state.parentToggle ? <div className="p-3"><ShutterStockGallery components={this.state.components} focusMove={this.props.focusMove} /></div> : ''}
                </div>
            </div>

        </>);
    }
}

export default withParamsAndNavigate(EditorSidebar);