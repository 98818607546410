import React from 'react';
import BaseCom from './BaseCom';
import LoadingAlert from './LoadingAlert';
import { qreq } from '../shared/qrequest';
import DataTable from './DataTable';
import { withParamsAndNavigate } from './WithParamsAndNavigate';
import Alert from './Alert';
import AdminLayout from './AdminLayout';
import { Link } from 'react-router-dom';
import Icon from './Icon';
import { Pop } from './PopModal';
import Card from './Card';
import Globals from '../shared/Globals';
import FormInput from './FormInput';
import Tick from './Tick';
import './AdminSites.css';

class AdminSites extends BaseCom {

    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            user: null,
            list: [],
            loading: true,
            loaded: false,
            filter: {}
        };
        this.listRef = React.createRef();
        this.load = this.load.bind(this);
        this.scrollHandler = this.scrollHandler.bind(this);
        this.preview = this.preview.bind(this);
        this.search = this.search.bind(this);
    }

    componentDidMount() {
        qreq.get('/api/settings/editor', j => {
            if (j.item)
                this.setState({ settings: j.item });
        });
        this.load();
        document.addEventListener('scroll', this.scrollHandler);
    }

    componentWillUnmount() {
        document.removeEventListener('scroll', this.scrollHandler);
    }

    load(search) {
        this.setState({ search: search });
        qreq.get('/api/admin/domain/list' + (search ? '?search=' + search : ''), j => {
            if (j.list) {
                this.setState({ list: j.list, loaded: true, loading: false });
                if (j.list.length > 0)
                    this.scrollHandler();
            }
            else
                this.alert(j.errorMessage);
        }, this.unkownErrorCallback);
    }

    scrollHandler() {
        if (!this.listRef.current)
            return;
        const bottom = this.listRef.current.getBoundingClientRect().bottom - 50 <= window.innerHeight;
        if (bottom) {
            if (this.state.loading || this.state.endOfList)
                return;
            this.setState({ loading: true });
            qreq.get('/api/admin/domain/list?page=' + (this.state.page + 1) + (this.state.search ? '&search=' + this.state.search : ''), j => {
                if (j.list) {
                    if (j.list.length === 0)
                        this.setState({ endOfList: true });
                    else {
                        var list = [...this.state.list];
                        list = list.concat(j.list);
                        this.setState({ list: list, page: this.state.page + 1 });
                    }
                }
                else
                    this.alert(j.errorMessage);
                this.setState({ loading: false });

            }, () => {
                this.setState({ loading: false });
                this.unkownErrorCallback();
            });
        }
    }

    preview(site) {
        if (this.state.settings.renderUrl)
            window.open(this.state.settings.renderUrl.replace('*', site.masterID).replace('%', ''));
        else
            window.open('https://' + site.masterID + '.' + window.location.host + '/');
    }

    search() {
        if (this.state.filter?.search !== this.state.search) {
            this.setState({ search: this.state.filter?.search });
            this.load(this.state.filter?.search);
        }
    }

    getItemProgress = (item) => {
        let progress = 0;
        if (item.isPublished)
            progress += 20;
        if (item.isPurchased)
            progress += 20;
        if (item.isRegistered)
            progress += 20;
        if (item.isCertCreated)
            progress += 20;
        if (item.isDNSConfigured)
            progress += 20;
        return progress;
    }

    getItemStatus = (item) => {
        if (item.isPurchased)
            return 'Purchased';
        if (item.isPublished)
            return 'In Process of Publishing';
        return 'New';
    }

    getItemColor = (item) => {
        if (item.isPurchased)
            return '#28a745';
        if (item.isPublished)
            return '#ffc107';        
        return '#6c757d';
    }


    render() {

        if (!this.state.loaded)
            return (<div className="container-fluid"><LoadingAlert /></div>);

        if (this.state.errorMessage)
            return <Alert type="danger" icon="ExclamationTriangle" message={this.state.errorMessage} />


        return <AdminLayout>
            <Tick onTick={this.search} interval={1000} />
            <div className="container-fluid">
                <Card title="All Sites" headerButtons={<><Link to="/admin/sites/new" type="button" className="btn btn-primary"><Icon icon="faPlus" /> New Site</Link></>}>
                    <FormInput model={this.state.filter} type="text" name="search" label="Search" placeholder="Search" labelAsPlaceholder postInput={this.state.search ? <Icon role="button" title="Clear Search" icon="faClose" onClick={() => this.setState({ filter: { search: null } })} /> : null} />
                    <DataTable tableRef={this.listRef} columns={[
                        {
                            icon: 'Globe', tooltip: 'View Site', onClick: row => {
                                this.preview(row);
                            }
                        },
                        { icon: 'Pencil', link: '/editor/site/:id', tooltip: 'Edit Site' },
                        {
                            icon: 'Trash', tooltip: 'Delete Site', onClick: row => {
                                Pop.showConfirm('Are you sure you want to delete \'' + row.domainName + '\'?', 'Delete Site', () => {
                                    qreq.post('/api/admin/domain/delete', { ...row }, j => {
                                        if (j.errorCode === 0) {
                                            var list = [...this.state.list];
                                            var idx = list.indexOf(row);
                                            if (idx >= 0) {
                                                list.splice(idx, 1);
                                                this.setState({ list: list });
                                            }
                                        }
                                        else
                                            this.alert(j.errorMessage);
                                    });
                                });
                            }
                        },
                        { name: 'id', title: 'ID', link: '/editor/site/:id', tooltip: 'Edit Site', className: 'd-none d-md-table-cell' },
                        { name: 'domainName', title: 'Domain', link: '/editor/site/:id', tooltip: 'Edit Site' },
                        {
                            name: 'merchantName', title: 'Merchant', className: 'd-none d-md-table-cell', valueFunc: row => {
                                if (!row.merchantName)
                                    return 'None';
                                return row.merchantName;
                            }
                        },
                        {
                            name: 'organizationName', title: 'Organization', className: 'd-none d-md-table-cell', valueFunc: row => {
                                if (!row.organizationName)
                                    return 'None';
                                return row.organizationName;
                            }
                        },
                        {
                            name: 'status', title: 'Status', className: 'd-none d-md-table-cell', valueFunc: row => {
                                return <div className="admin-progress">
                                    <div className="admin-progress-status">{this.getItemStatus(row)}</div>
                                    <div className="admin-progress-bar" role="progressbar" style={{ width: this.getItemProgress(row) + '%', backgroundColor: this.getItemColor(row) }} aria-valuenow={this.getItemProgress(row)} aria-valuemin="0" aria-valuemax="100"></div>
                                </div>;
                            }
                        },
                        { name: 'createdDate', title: 'Created', format: 'datetime', className: 'd-none d-md-table-cell text-end' }
                    ]} data={this.state.list} loading={this.state.loading} />
                </Card>
            </div>
        </AdminLayout>;
    }
}

export default withParamsAndNavigate(AdminSites);