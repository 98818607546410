import Auth from "../shared/Auth";
import BaseCom from "./BaseCom";
import { Pop } from "./PopModal";
import { withParamsAndNavigate } from "./WithParamsAndNavigate";
import { Link, NavLink, Outlet } from "react-router-dom";
import './AdminLayout.css';
import Icon from "./Icon";
import { Dropdown } from "react-bootstrap";

class AdminLayoutWithOutlet extends BaseCom {
    componentDidMount() {
        Auth.getLogin(u => {
            if (!u || !Auth.isAdmin())
                this.props.navigate('/login');
            else {
                this.setState({ user: u });
            }
        });
    }

    render() {
        return <>
            <nav className="AdminLayout-nav navbar navbar-expand-lg navbar-light bg-light position-fixed" style={{ marginTop: '-65px' }}>
                <div className="container-fluid">
                    <Link to="/admin" className="navbar-brand">Administration</Link>
                    <button onClick={() => this.setState({ toggleNav: !this.state.toggleNav })} className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                </div>
            </nav>
            <div className="d-flex">
                <div id="navbarSupportedContent" className={'AdminLayout-sidebar flex-column flex-shrink-0 p-3 ' + (this.state.toggleNav ? 'd-flex position-fixed' : 'd-none d-md-flex')}>
                    <div className="sidebar-title">
                        Main
                    </div>
                    <ul className="nav nav-pills flex-column">
                        <li className="nav-item">
                            <NavLink to="/admin" end className="nav-link">
                                <Icon icon="faDashboard" />
                                <span>Dashboard</span>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to="/admin/templates" className="nav-link">
                                <Icon icon="faObjectGroup" />
                                <span>Templates</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/admin/sites" className="nav-link">
                                <Icon icon="faGlobe" />
                                <span>Sites</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/admin/merchants" className="nav-link">
                                <Icon icon="faStore" />
                                <span>Merchants</span>
                            </NavLink>
                        </li>
                    </ul>
                    <div className="sidebar-title">
                        Management
                    </div>
                    <ul className="nav nav-pills flex-column">
                        <li>
                            <NavLink to="/admin/users" className="nav-link">
                                <Icon icon="faUsers" />
                                <span>Users</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/admin/organizations" className="nav-link">
                                <Icon icon="faBuilding" />
                                <span>Organizations</span>
                            </NavLink>
                        </li>
                    </ul>

                    <hr className="mt-auto" />
                    {this.state.user ? <Dropdown drop="up" className="text-small shadow">
                        <Dropdown.Toggle variant="light" className="w-100">
                            <Icon icon="faUser" className="me-2" />
                            <strong>{this.state.user.fullName}</strong>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="w-100">
                            <Dropdown.Item onClick={e => {
                                e.preventDefault();
                                Pop.showConfirm(<>Are you sure you want to logout?</>, 'Logout', () => {
                                    this.props.navigate('/logout');
                                });
                            }}>Sign out</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown> : ''}
                </div>
                <div className="AdminLayout-content container-fluid">
                    <Outlet />
                </div>
            </div>
        </>;
    }
}

export default withParamsAndNavigate(AdminLayoutWithOutlet);